import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import $ from 'jquery'
let ScrollMagic

import Layout from '../components/Layout'
import PageTitle from '../components/Blocks/PageTitle'
import Position from '../components/Position/Teaser_2'
import OngoingPositions from '../components/Blocks/OngoingPositions'
import JoinTeam from '../components/Blocks/JoinTeam'
import SEO from '../components/SEO'
import {FormattedMessage} from "react-intl";
import LocalizedLink from "../components/LocalizedLink";

class PageOpenPositions extends React.PureComponent {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            mainHeight: 0
        };
        this.controller = null;
    }

    getHeight(param, e) {
        return this.state[param + 'Height'];
    }

    setHeights() {
        this.setState({
            mainHeight: $('#main > .row > .col-md-9').height() - $('.side-menu').height() - $('#block--ongoing-positions').outerHeight(true) - $('#block--join-team').outerHeight(true) - 100
        });
    }

    componentDidMount() {
        var self = this;
        this._isMounted = true;

        ScrollMagic = require('../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic');
        this.controller = new ScrollMagic.Controller();

        $(window).on('load resize', function() {
            if (self._isMounted) {
                self.setHeights();
            }
        });
        $(window).resize();

        new ScrollMagic.Scene({
            triggerHook: 'onLeave',
            triggerElement: '.side-menu',
            duration: self.getHeight.bind(this,'main'),
            offset: -100
        })
            .setPin('.side-menu')
            .addTo(this.controller);
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            $(window).resize();
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { pageContext } = this.props;
        const {lang, jobs} = pageContext
        const pageTitle = (lang === 'fr') ? 'Postes ouverts' : 'Open positions'

        return (
            <Layout locale={lang}>
                <SEO title={pageTitle}/>
                <PageTitle titleId="open-positions"/>
                <section id="main" className="container">
                    <div className="row">
                        <div className='col-md-3 position-absolute'>
                            <SideMenu lang={lang}
                                      jobs={jobs}/>
                        </div>
                        <div className='col-md-9 ml-md-auto'>

                            <div className="views views--positions">
                                {jobs.map((job, index) => (
                                    <Position key={index}
                                              data={job}
                                    />
                                ))}
                            </div>

                            <OngoingPositions/>
                            <JoinTeam/>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
export default PageOpenPositions

/****************************************************
 *  Side menu component
 ****************************************************/
const SideMenu = (props) => {
    const { jobs } = props
    return (
        <ul className='side-menu'>
            { jobs.map((job, index) => (
                <li key={index}>
                    <Link to={`${job.path}`}>{job.title}</Link>
                </li>
                )
            )}
            <li>
                <LocalizedLink to='_apply_now'>
                    <FormattedMessage id='position--apply'/>
                </LocalizedLink>
            </li>
        </ul>
    )
}
SideMenu.propTypes = {
    jobs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        })
    ),
    lang: PropTypes.string.isRequired
}