import React from 'react'
import PropTypes from 'prop-types'
import LocalizedLink from '../LocalizedLink'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

const Position = (props) => {
    const { title, blurb_1, blurb_2, path, application_url } = props.data

    return (
        <article className='node--position node--position--teaser-listing'>
            <h2>{title}</h2>
            <div className='row'>
                <div className='col-md-12'>
                    <p>{blurb_1}</p>
                </div>
            </div>

            <ul className='node-links list-unstyled list-inline'>
                <li className='list-inline-item'>
                    <LocalizedLink to={application_url}
                          className='btn btn-blue'>
                        <FormattedMessage id='position--apply'/>
                    </LocalizedLink>
                </li>
                <li className='list-inline-item'>
                    <Link to={path}
                          className='link-text-black'>
                        <FormattedMessage id='position--full-description'/>
                    </Link>
                </li>
            </ul>
        </article>
    )
}

Position.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        blurb_1: PropTypes.string.isRequired,
        blurb_2: PropTypes.string
    })
}

export default Position
