import React from 'react'
import { FormattedMessage } from 'react-intl'

import LocalizedLink from '../../LocalizedLink'
import BlockBase from '../BlockBase'
import BlockMediaBase from '../BlockMediaBase'

import Image from './images/join-team.jpg'

const JoinTeam = () => (
    <BlockBase id='join-team' invisibleTitle={true}>
        <BlockMediaBase image={Image}>
            <h6><FormattedMessage id='block--join-team--text-1'/></h6>
            <p><FormattedMessage id='block--join-team--text-2'/></p>

            <LocalizedLink to='_apply_now' className='link-text'>
                <FormattedMessage id='block--join-team--link'/>
            </LocalizedLink>
        </BlockMediaBase>
    </BlockBase>
)

export default JoinTeam