import React from 'react'
import { FormattedMessage } from "react-intl"
import LocalizedLink from '../../LocalizedLink'

const OngoingPositions = () => (
    <div id='block--ongoing-positions' className='block--ongoing-positions jumbotron jumbotron-fluid'>
        <div className='container'>
            <h2><FormattedMessage id='block--ongoing-positions--title'/></h2>

            <div className="row">
                <div className="col-md-6">
                    <FormattedMessage id='block--ongoing-positions--text-1'/>
                </div>
                <div className="col-md-6">
                    <FormattedMessage id='block--ongoing-positions--text-2'/>
                </div>
            </div>

            <LocalizedLink to='/'>
                <FormattedMessage id='block--ongoing-positions--hello'/>
            </LocalizedLink>
        </div>
    </div>
)

export default OngoingPositions
